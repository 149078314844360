<template>
  <v-card class="none-overflow">
    <v-img
      :src="imgUrl"
      v-parallax.modifier="0.1"
      height="18rem"
      class="empty-noice-img"
      gradient="to top right, #ffaf6444, rgba(52,7,76,.3)"
    >
    </v-img>
    <div class="empty-noice">
      <div class="empty-noice-title">白噪音播放器</div>
      <div class="empty-noice-subtitle">播放适合学习的背景音</div>
      <v-btn
        class="audio-player rounded-lg"
        @click="openDrawer"
        @touchstart="zzzShort"
        @touchend="zzzMid"
        ><v-icon>mdi-music-note</v-icon>GO</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { isEmpty } from "@/utils/common";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "emptyNoice",
  mixins: [buttonVibrate],
  data() {
    return {
      sysImg: [require("@/assets/background/colorful/material2.png")],
      emptyNoice: {
        img: null,
      },
    };
  },
  computed: {
    imgUrl() {
      if (isEmpty(this.emptyNoice.img)) {
        return this.sysImg[0];
      }
      return this.emptyNoice.img;
    },
  },
  methods: {
    openDrawer() {
      this.$toast.success("我们将播放器放在了侧边抽屉");
      this.$store.dispatch("setShowDrawer", true);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.empty-noice-img {
  position: absolute;
  left: 0;
  top: -2rem;
  opacity: 0.8;
}

.empty-noice {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  color: #ffffff;
  padding: 1rem;
  letter-spacing: 0.05rem;
  .audio-player {
    position: absolute;
    bottom: 1.2rem;
    right: 1.2rem;
    width: 7rem;
    height: 3rem;
    background-color: #d1f1a9;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
  }
  .empty-noice-title {
    width: 100%;
    line-height: 2rem;
    letter-spacing: 0.15rem;
    font-size: 1.1rem;
    text-shadow: 1px 1px 5px #00000085;
  }
  .empty-noice-subtitle {
    width: 100%;
    line-height: 1.5rem;
    letter-spacing: 0.15rem;
    font-size: 0.8rem;
    text-shadow: 1px 1px 2px #00000085;
  }
}
</style>
